/************************************************************
  Enter your Supabase Key and Supabase URL below
  1. go to https://supabase.io, 
  2. log into your project
  3. click "settings" on the left (at the bottom)
  4. click "API"
  5. copy "URL" to SUPABASE_URL below
  6. copy your "API Key" (anon public) to SUPABASE_KEY below
  7. save this file and rename it to "supabase.ts"
*************************************************************/
export const keys = {
    SUPABASE_URL: 'https://okzrunekirvyinjfmcjz.supabase.co',
    SUPABASE_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MTQyNTcwMywiZXhwIjoxOTU3MDAxNzAzfQ.PnqMVrxVY9b7bFrXu38qdwzHd290LYJerYzafdkAXac',
  };
  